.header-component {
    display: flex;
    height: auto;
    flex-direction: column;
    background: #14171D;
    padding-top: 20px;
}

.header-component-hr {
    background: rgba(255, 255, 255, 0.3);
    width: 100%;
}

.navbar-left {
    padding-left: 4%;
}

.navbar-right {
    padding-right: 4%;
}

.header-link-label {
    padding-left: 25px;
    padding-right: 25px;
}

.header-link {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    cursor: pointer;
    text-decoration: none;
}

.header-link:hover {
    text-decoration: none;
    /*font-weight: bold;*/
    /*color: white;*/
    color: #127A4F;
}

.header-component-logo-label {
    color: white;
    font-weight: bold;
}

.navbar-mobile {
    display: none;
}

.footer-share-link-copyright {
    color: #898989 ;
    text-decoration: none;
}

.footer-share-link-copyright:hover {
    font-weight: bold;
    color: #127A4F;
    text-decoration: none;
}

@media screen and (max-width: 800px) {
    .navbar-desktop {
        display: none;
    }

    .navbar-mobile {
        display: flex;
    }

    .button-menu {
        color: white !important;
    }

    .header-link-mobile {
     /*color: #14171D;*/
        color: white;
        padding-top: 10px ;
        padding-bottom: 10px;
    }

    .header-link-mobile:hover {
        text-decoration: none;
        font-weight: bold;
        color: white;
        /*color: #14171D;*/
    }

    .MuiList-padding {
        padding: 10px 5px !important;;
    }

    .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
        width: 100% !important;
        display: flex;
        justify-content: center;
        background: rgba(35, 38, 44, 1);
        border-radius: 10px;
        left: 16px !important;
    }

    .MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
        display: flex;
        justify-content: center;
    }

    .MuiButtonBase-root.MuiButton-root.MuiButton-text.button-menu {
        /*color: #127A4F !important;*/
        color: #ffffff !important;
    }
}

@media screen and (max-width: 600px) {
    .navbar-mobile {
        display: flex;
        position: fixed;
        z-index: 999;
        background: #137a4f;
        border-radius: 50%;
    }

}


