/*.list-details-specifications-component {*/
/*    height: 1000px;*/
/*    background: #14171D url('img/back2.png') no-repeat center center;*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    background-size: contain;*/
/*}*/

/*.list-details-specifications-left {*/
/*    height: 500px;*/
/*    background: #14171D url('img/backTree.png') no-repeat center center;*/
/*    !*background-size: contain;*!*/
/*    !*width: 100%;*!*/
/*    background-size: cover;*/
/*    border-radius: 50px;*/
/*}*/

/*.list-details-specifications-img-container {*/
/*    justify-content: center;*/
/*}*/

/*.list-details-specifications-left-img {*/
/*    width: 65%;*/
/*}*/

/*.list-details-specifications-container {*/
/*    padding-top: 50px;*/
/*    padding-left: 6%;*/
/*}*/

/*.list-details-specifications-left-title {*/
/*    font-style: normal;*/
/*    font-weight: bold;*/
/*    font-size: 25px;*/
/*    line-height: 30px;*/
/*    color: #FFFFFF;*/
/*}*/

/*.list-details-specifications-left-label {*/
/*    font-style: normal;*/
/*    font-weight: normal;*/
/*    font-size: 18px;*/
/*    line-height: 22px;*/
/*    color: #FFFFFF;*/
/*    width: 80%;*/
/*    text-align: left;*/
/*}*/

/*.list-details-specifications-left-img {*/
/*    width: 100%;*/
/*    padding-top: 50px;*/
/*}*/

/*.list-details-specifications-right-up {*/
/*    height: 418px;*/
/*    background: rgba(35, 38, 44, 0.5);*/
/*    border-radius: 50px;*/
/*    width: 100%;*/
/*    text-align: center;*/
/*}*/

/*.list-details-specifications-right-down {*/
/*    height: 418px;*/
/*    background: rgba(35, 38, 44, 0.5);*/
/*    border-radius: 50px;*/
/*    margin-top: 60px;*/
/*    text-align: center;*/
/*}*/

/*@media screen and (max-width: 800px) {*/
/*    .list-details-specifications-component {*/
/*        height: auto;*/
/*    }*/

/*    .list-details-specifications-left {*/
/*        height: 890px;*/
/*        width: 90%;*/
/*        margin-top: 20px;*/
/*    }*/

/*    .list-details-specifications-left-label {*/
/*        font-size: 15px;*/
/*    }*/

/*    .list-details-specifications-right-up {*/
/*        height: auto;*/
/*        width: 90% !important;*/
/*        padding-top: 20px;*/
/*        padding-bottom: 40px;*/
/*        margin-top: 20px;*/
/*    }*/

/*    .list-details-specifications-right-down {*/
/*        height: auto;*/
/*        width: 90% !important;*/
/*        margin-top: 20px;*/
/*        padding-top: 20px;*/
/*        padding-bottom: 40px;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 600px) {*/
/*    .list-details-specifications-left {*/
/*       height: auto;*/
/*        width: 90%;*/
/*        margin-top: 20px;*/
/*    }*/
/*}*/