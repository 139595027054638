.list-details-component {
    /*height: 1100px;*/
    margin-bottom: 100px;
    margin-top: 100px;
    /* Фоновое изображение всегда отцентрировано по вертикали и горизонтали */
    /* Фоновое изображение не повторяется */
    /* Фоновое изображение зафиксировано в окне просмотра, поэтому оно не смещается, когда высота контента больше высоты изображения */
    background: #14171D url('img/back.png') no-repeat center center;
    background-size: contain;
    display: flex;
    flex-direction: row;
    margin-left: 10%;
    margin-right: 10%;
}

.list-details-info-container {
    margin-left: 3%;
    margin-right: 3%;
}

.list-details-specifications-left {
    height: 500px;
    background: #14171D url('img/backTree.png') no-repeat center center;
    /*background-size: contain;*/
    width: 98%;
    background-size: cover;
    border-radius: 50px;
}

.list-details-specifications-img-container {
    justify-content: center;
}

.list-details-specifications-left-img {
    width: 65%;
    position: relative;
    top: -20px;
}


.list-details-info-text {
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 61px;
    letter-spacing: 0.025em;
    color: #FFFFFF;
}

.list-details-info {
    padding-top: 15px;
    padding-bottom: 40px;
}

.list-details-info-label {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 150%;
    color: #FFFFFF;
}

.list-details-info-about-work-title {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
}

.list-details-info-about-work-label {
    padding-left: 13px;
}

.list-details-specifications-container {
    margin-left: 4%;
    margin-top: 1%
}

.list-details-specifications-left-title {
    font-size: 2rem;
    font-style: normal;
    font-weight: bold;
    line-height: 30px;
    color: #FFFFFF;
    padding-top: 20px;
}

.list-details-specifications-left-label {
    font-size: 15px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

/*правая часть*/

.list-details-download-app {
    margin-top: 140px;
    margin-left: 3%;
    margin-right: 3%;
}

.download-now {
    color: #222222;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
}

.button-download {
    padding-top: 22px;
    padding-bottom: 30px;
}

.phone-download {
    background: #333333;
    border-radius: 25px;
    display: flex;
    width: 45%;
    padding-top: 10px;
    padding-bottom: 5px;
    border: #333333;
    align-items: center;
}

.phone-download:hover {
    /*background: #171717;*/
    border: 3px solid #171717;
}

.download-picture-img {
    /*width: 100%;*/
    position: relative;
    width: 80%;
    top: -100px;
}

.phone-icon {
    width: 45%;
    padding-left: 10px;
    padding-right: 10px;
}

.download-picture-container {
    background: #FFFFFF;
    border-radius: 10px;
}

.download-picture {
    height: 360px;
}

.phone-download-head {
    font-size: 9px;
    color: #FFFFFF;
    font-weight: bold;
}

.phone-download-title {
    font-size: 12px;
    color: #FFFFFF;
}

.statistical-information-container {
    margin-top: 20px;
    text-align: center;
}

.statistical-information {
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 150%;
    color: #127A4F;
}

.statistical-information-label {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 150%;
    color: #FFFFFF;
}

@media screen and (max-width: 768px) {
    .list-details-info-text {
        text-align: center;
    }

    .list-details-info {
        text-align: center;
    }

    .list-details-specifications-container {
        align-items: center !important;
    }

}

@media screen and (max-width: 500px) {

    .list-details-specifications-left {
        height: auto;
    }

    .list-details-component {
        margin-left: 2%;
        margin-right: 2%;
    }

    .list-details-info-container {
        margin-bottom: 10%;
    }

    .list-details-download-app {
        margin-top: 155px;
        margin-left: 3%;
        margin-right: 3%;
    }

    .download-picture {
        height: 300px;
    }

    .list-details-specifications-container {
        margin-left: 0%;
    }

    .list-details-specifications-left-img {
        height: 98% !important;
        width: 98% !important;
        position: relative;
        top: 25px;
    }

    .list-details-info-text {
        font-size: 30px;
        line-height: 35px;
    }

    .list-details-specifications-left-title {
        font-size: 19px;
    }

    .list-details-specifications-left-label {
        font-size: 15px;
        text-align: center;
    }
}

@media screen and (max-width: 330px) {
    .list-details-specifications-left-title {
        font-size: 18px;
    }
}
