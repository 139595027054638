.list-form-component {
    height: 1000px;
    margin-bottom: 100px;
    margin-top: 100px;
    background: #14171D url('img/back-form.png') no-repeat center center;
    background-size: cover;
}

.list-form-component-container {
    width: 950px !important;
    background: rgba(35, 38, 44, 0.5);
    border-radius: 10px;
}

.list-form-head {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 37px;
    color: #FFFFFF;
    margin-left: 4%;
    margin-top: 30px;
}

.list-form-input-control {
    margin-top: 20px;
    margin-bottom: 20px;
}

.list-form-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 6px;
    color: #127A4F;
}

.list-form-required {
    color: #ff061f;
    margin-bottom: 0px;
}

.list-form-done-submit {
    color: #127A4F;
    margin-bottom: 0px;
}

.list-form-input {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 7px;
    width: 100%;
    padding: 5px 15px 5px 15px;
}

.error-valid {
    border: 2px solid #ff061f !important;
}

.pulse {
    box-shadow: 0 0 0 rgba(232, 7, 7, 0.4);
    animation: pulse 2s 2;
    -webkit-animation: pulse 2s ease-in-out 2;
    -moz-animation: pulse 2s ease-in-out 2;
    -ms-animation: pulse 2s ease-in-out 2;
    -o-animation: pulse 2s ease-in-out 2;
}
.pulse:hover {
    animation: none;
}



@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(232, 7, 7, 0.3);
        -webkit-animation-timing-function: ease-in-out;
        box-shadow: 0 0 0 0 rgba(232, 7, 7, 0.3);
    @include transform(scale(.9));
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(232, 7, 7, 0.3);
        box-shadow: 0 0 0 8px rgba(232, 7, 7, 0.3);
        -webkit-animation-timing-function: ease-in-out;
    @include transform(scale(1));
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(232, 7, 7, 0.3);
        box-shadow: 0 0 0 0 rgba(232, 7, 7, 0.3);
        -webkit-animation-timing-function: ease-in-out;
    @include transform(scale(.9));
    }
}
@keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(232, 7, 7, 0.3);
        -moz-box-shadow: 0 0 0 0 rgba(232, 7, 7, 0.3);
        box-shadow: 0 0 0 0 rgba(232, 7, 7, 0.3);
    @include transform(scale(.9));
    }
    70% {
        -webkit-box-shadow: 0 0 0 8px rgba(232, 7, 7, 0.3);
        -moz-box-shadow: 0 0 0 8px rgba(232, 7, 7, 0.3);
        box-shadow: 0 0 0 8px rgba(232, 7, 7, 0.2);
    @include transform(scale(1));
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(232, 7, 7, 0.3);
        -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
        box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    @include transform(scale(.9));
    }
}
@-o-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(232, 7, 7, 0.3);
        -moz-box-shadow: 0 0 0 0 rgba(232, 7, 7, 0.3);
        box-shadow: 0 0 0 0 rgba(232, 7, 7, 0.3);
    @include transform(scale(.9));
    }
    70% {
        -webkit-box-shadow: 0 0 0 8px rgba(232, 7, 7, 0.3);
        -moz-box-shadow: 0 0 0 8px rgba(232, 7, 7, 0.3);
        box-shadow: 0 0 0 8px rgba(232, 7, 7, 0.2);
    @include transform(scale(1));
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(232, 7, 7, 0.3);
        -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
        box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    @include transform(scale(.9));
    }
}
@-moz-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(232, 7, 7, 0.3);
        -moz-box-shadow: 0 0 0 0 rgba(232, 7, 7, 0.3);
        box-shadow: 0 0 0 0 rgba(232, 7, 7, 0.3);
    @include transform(scale(.9));
    }
    70% {
        -webkit-box-shadow: 0 0 0 5px rgba(232, 7, 7, 0.3);
        -moz-box-shadow: 0 0 0 5px rgba(232, 7, 7, 0.3);
        box-shadow: 0 0 0 8px rgba(232, 7, 7, 0.2);
    @include transform(scale(1));
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(232, 7, 7, 0.3);
        -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
        box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    @include transform(scale(.9));
    }
}

.list-form-select-container {
    width: 100%;
}

.list-form-select {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 7px;
    padding: 5px 15px 5px 15px;
    width: 100%;
}

.list-form-textField {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 7px;
    width: 100%;
    padding: 5px 15px 5px 15px;
}

.list-form-outlined {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 7px;
    width: 100%;
    padding: 5px 15px 5px 15px;
}

.list-form-button-control {
    margin-right: 4%;
    margin-bottom: 30px;
    margin-top: 40px;
}

.list-form-button {
    margin-left: 20px !important;
}

.submit-button {
    background: #127A4F !important;
    border: 1px solid #127A4F !important;
    box-sizing: border-box !important;
    border-radius: 7px !important;
}

.submit-button-disabled {
    opacity: 0.5;
}

.submit-button-icon {
    margin-right: 5px;
}

.submit-button-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    text-transform: none;
    color: #FFFFFF;
    display: flex;
}

@media screen and (max-width: 950px) {
    .list-form-component-container {
        width: 100% !important;
    }
}

@media screen and (max-width: 600px) {

    .list-form-component {
        height: auto;
    }

    .form-container {
        width: 90%;
        margin-top: 20px;
    }

    .list-form-input {
        width: 90%;
    }

    .list-form-input-container {
        justify-content: center;
        display: flex;
    }

    .list-form-input-title {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .list-form-title {
        margin-left: 5%;
    }

    .list-form-select {
        width: 90%;
    }

    .list-form-outlined {
        width: 90%;
    }

    .list-form-button-control {
       justify-content: center !important;
    }

    .list-form-button.submit-button {
        margin-top: 20px;
    }
}
