.list-detail-app-component {
    height: 580px;
    margin-top: 100px;
    margin-bottom: 100px;
    display: flex;
    background: rgba(35, 38, 44, 0.5);
    border-radius: 10px;
    flex-direction: row;
}

.from-the-developers-container {
    margin-left: 10%;
    margin-right: 10%;
}

.from-the-developers-head {
    position: relative;
    top: -8px;
}

/*.from-the-developers {*/
/*    !*background: rgba(35, 38, 44, 0.5);*!*/
/*    border-radius: 10px;*/
/*    height: 100%;*/
/*}*/

.from-the-developers-img {
    width: 98%;
}

.pyramid-label-1 {
    padding-top: 25%;
}

.pyramid-label-2 {
    padding-top: 58%;
}

.pyramid-label-3 {
    padding-top: 30%;
}

.pyramid-label {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    color: #FFFFFF;
}

.pyramid-desktop {
    position: relative;
    top: -17px;
}

.from-the-developers-right {
    background: #127A4F;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 10px;
    width: 150px;
    height: 40px;
    text-align: center;
}

.from-the-developers-right-icon {
    padding-right: 1%;
    margin-right: 5%;
}

.from-the-developers-right-icon-image:hover {
    transform: rotate(180deg);
}

.from-the-developers-right-title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    padding-bottom: 15px;
}

.from-the-developers-right-label {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    padding-top: 5px;
    padding-bottom: 5px;
}

.from-the-developers-right-info.developers-info {
    text-align: left;
}

.from-the-developers-right-info-container {
    text-align: justify;
    padding-right: 10%;
    padding-bottom: 6%;
}

.from-the-developers-right-info {
    margin-bottom: 20px;
}

.pyramid-mobile {
    display: none;
}

.icon-figure {
    margin-right: 10px !important;
    /*display: flex;*/
}

@media screen and (max-width: 1280px) {
    .pyramid-label-1 {
        padding-top: 20%;
    }

    .pyramid-label-2 {
        padding-top: 35%;
    }

    .pyramid-label-3 {
        padding-top: 10%;
    }

    .list-detail-app-component {
        height: auto;
    }

    .pyramid-desktop {
        position: static;
        top: 0;
    }
}

@media screen and (max-width: 1279px) {

    .list-detail-app-component {
        height: auto;
    }

    .pyramid-label-1 {
        padding-top: 15%;
    }

    .pyramid-label-2 {
        padding-top: 32%;
    }

    .pyramid-label-3 {
        padding-top: 8%;
    }
}

@media screen and (max-width: 1024px) {
    .pyramid-label-1 {
        padding-top: 25%;
    }

    .pyramid-label-2 {
        padding-top: 50%;
    }

    .pyramid-label-3 {
        padding-top: 25%;
    }

    .from-the-developers-head {
        margin-top: 50px;
    }

    .from-the-developers-right-title {
        text-align: center;
    }

    .from-the-developers-right-label {
        font-size: 20px;
        line-height: 24px;
    }

    .from-the-developers-right-info-container {
        padding-right: 10px;
        margin-bottom: 0px;
    }

    .from-the-developers {
        padding-left: 2%;
        padding-right: 2%;
        text-align: center;
    }

    .from-the-developers-right-icon {
        justify-content: flex-start !important;
    }

    .from-the-developers-right-info.developers-info {
        text-align: center !important;
    }
}


@media screen and (max-width: 768px) {
    .pyramid-label-1 {
        padding-top: 20%;
    }

    .pyramid-label-2 {
        padding-top: 35%;
    }

    .pyramid-label-3 {
        padding-top: 10%;
    }

    .from-the-developers-head {
        margin-top: 50px;
    }

    .from-the-developers-right-title {
        text-align: center;
    }

    .from-the-developers-right-label {
        font-size: 15px;
        line-height: 24px;
    }

    .from-the-developers-right-info-container {
        padding-right: 10px;
        margin-bottom: 0px;
    }

    .from-the-developers {
        padding-left: 2%;
        padding-right: 2%;
        text-align: center;
    }

    .from-the-developers-right-icon {
        justify-content: flex-start !important;
    }
}

@media screen and (max-width: 500px) {

    .from-the-developers-head {
        margin-top: 50px;
    }

    .from-the-developers-head {
        padding-left: 4%;
        padding-right: 4%;
    }

    .from-the-developers-right-icon-image {
        width: 30%;
    }

    .from-the-developers-right-title {
        text-align: center;
    }

    .from-the-developers-right-label {
        font-size: 15px;
        line-height: 24px;
    }

    .from-the-developers-right-info-container {
        padding-right: 10px;
        margin-bottom: 0px;
    }

    .from-the-developers {
        padding-left: 2%;
        padding-right: 2%;
        text-align: center;
    }

    .from-the-developers-right-icon {
        justify-content: flex-start !important;
    }

    .from-the-developers-container {
        margin-left: 0;
        margin-right: 0;
    }

    .from-the-developers-right-icon {
        margin-right: 0;
    }

    .pyramid-label-1 {
        padding-top: 10%;
    }

    .pyramid-label-2 {
        padding-top: 15%;
    }

    .pyramid-label-3 {
        padding-top: 15%;
    }

    .pyramid-label {
        font-size: 10px;
        display: flex;
    }

    .pyramid-mobile {
        display: flex;
    }

    .pyramid-desktop {
        display: none;
    }

    .icon-figure-done {
        width: 50%;
    }

    .icon-figure.castle {
        width: 40%;
    }
}

@media screen and (max-width: 375px) {

    .pyramid-label-1 {
        padding-top: 10%;
    }

    .pyramid-label-2 {
        padding-top: 15%;
    }

    .pyramid-label-3 {
        padding-top: 10%;
    }
}

@media screen and (max-width: 340px) {

    .circle {
        width: 4px;
        height: 4px;
        background: #127A4F;
        border-radius: 50%;
        margin-right: 4px;
    }

    .pyramid-label-1 {
        padding-top: 10%;
    }

    .pyramid-label-2 {
        padding-top: 10%;
    }

    .pyramid-label-3 {
        padding-top: 10%;
    }

    .pyramid-label {
        font-size: 9px;
        line-height: 20px;
    }
}