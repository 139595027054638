.footer-component-hr {
    background: rgba(255, 255, 255, 0.3);
    width: 100%;
}

.footer-component {
    height: 250px;
    background: #14171D;
    display: flex;
    flex-direction: column;
}

.footer-up {
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-logo > img {
    padding-bottom: 15px;
}

.footer-logo > span {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
    text-transform: uppercase;
}

.footer-info-label {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    padding: 0px 15px 0 15px;
}

.footer-info-label-link {
    color: #FFFFFF;
    text-decoration: none;
}

.footer-info-label-link:hover {
    /*color: #00bc8c;*/
    color: #127A4F;
    font-weight: bold;
    text-decoration: none;
}

.footer-down {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer-copyright {
    padding-right: 4%;
}

.footer-copyright > span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #898989;
    padding: 0px 15px 0 15px;
}

@media screen and (max-width: 800px) {
    .footer-component {
        height: auto;
    }

    .footer-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footer-down {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .footer-info-label {
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: center;
        color: #898989;
    }

    .footer-share {
        display: flex;
        justify-content: center;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .footer-copyright {
        display: flex;
        justify-content: center;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .footer-copyright {
        justify-content: center !important;
    }
}